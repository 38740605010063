import { createRoot } from 'react-dom/client';
import { Suspense } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { AppLoader} from './AppLoading'
import App from './App';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';


let { NODE_ENV } = process.env;

var faro = initializeFaro({
  url: '/telemetry',
  app: {
    name: 'capcentermap',
    version: '1.0.0',
    environment: NODE_ENV
  },
  instrumentations: [
    ...getWebInstrumentations(),
    new TracingInstrumentation(),
  ],
});

const container = document.getElementById('app');
const root = createRoot(container);

root.render(<Suspense fallback={<AppLoader />}><App /></Suspense>);
