
import React, { useRef, useEffect, useState } from 'react';
import { MagnifyingGlassIcon, GlobeAltIcon, PhoneIcon } from '@heroicons/react/20/solid'
import markerImageRed  from '../assets/mapbox-marker-icon-20px-red.png';
import markerImageBlue from '../assets/mapbox-marker-icon-20px-blue.png';

import { roundedDistance } from '../utils';

export default function ListItem({coordinates, properties, selected, mapRef}) {
  const goTo = (position, addy) => {
    mapRef.current.flyTo({
      center: position,
      zoom: 12.1
    });
  }

  return (
        <div className={`prose prose-xl cursor-pointer p-[10px] h-auto ${selected == properties.Address ? 'bg-gray-100' : ''}`} onClick={() => goTo(coordinates, properties.Address)}>
              <span className="block text-red-700">
    {!properties.NetworkorFRC.startsWith('FRC') &&<img src={markerImageBlue} className="inline-flex h-10 w-auto mr-4"/>}
    {properties.NetworkorFRC.startsWith('FRC') &&<img src={markerImageRed} className="inline-flex h-10 w-auto mr-4"/>}
    {properties.Organization}
    </span>
              {!properties.NetworkorFRC.startsWith('FRC') && <span className="text-gray-700 text-xs font-bold">Network: {properties.NetworkorFRC}
                </span>}
              {properties.distance && <div className="text-black text-sm font-bold">{roundedDistance(properties.distance)} miles</div>}
              <div className="text-black text-sm">{properties.Address}, {properties.City}</div>
              <div className="flex flex-row w-full md:w-4/5 justify-between mt-2">
                <a type="button" target="_blank" href={properties.Website} className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              Go to Website

                  <GlobeAltIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                </a>
                {properties.PhoneNumber &&
                <a type="button" href={`tel:${properties.PhoneNumber}`} className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              Call the Center

                  <PhoneIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                </a>}
              </div>
          </div>
  )
}
