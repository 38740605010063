import React, { useRef, useEffect, useState, useReducer } from 'react';
import { Popover, Dialog } from '@headlessui/react'

import Fuse from 'fuse.js'
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from 'mapbox-gl-geocoder';
import './index.css'
import './styles/custom-geocode.css'
import places from '../data/output.json'
import markerImageRed  from './assets/mapbox-marker-icon-20px-red.png';
import markerImageBlue from './assets/mapbox-marker-icon-20px-blue.png';
import * as turf from '@turf/turf'

import { MagnifyingGlassIcon, GlobeAmericasIcon, GlobeAltIcon, RectangleGroupIcon, XMarkIcon } from '@heroicons/react/20/solid'

import ListItem from './components/entry'
import { roundedDistance } from './utils'

const layers = [
  'FRCs',
  'Network'
];
const colors = [
  '#f84d4d',
  '#4264fb',
];

mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN;

export default function App() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const geocoder = useRef(null);
  const fuse = useRef(null);
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [lng, setLng] = useState(-119.84048737114143);
  const [lat, setLat] = useState(37.15186142364033);
  const [zoom, setZoom] = useState(5.25);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const goTo = (position, addy) => {
    setSelected(addy);
    map.current.flyTo({
      center: position,
      zoom: 12.1
    });
  }

  function getMyLocation() {
      function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        setLat(latitude)
        setLng(longitude)


      forceUpdate();
      setIsOpen(true);
      const options = { units: 'miles' };

      for (const frc of places) {
        frc.properties.distance = turf.distance(
          turf.point([lng, lat]),
          frc.geometry,
          options
        );
      }
      places.sort((a, b) => {
        if (a.properties.distance > b.properties.distance) {
          return 1;
        }
        if (a.properties.distance < b.properties.distance) {
          return -1;
        }
        return 0; // a must be equal to b
      });

      }

      function error() {
      }

      if (!navigator.geolocation) {
        alert("Geolocation is not supported by your device; Enter your nearest address instead");
      } else {
        navigator.geolocation.getCurrentPosition(success, error);
      }

}

  function createPopUp(currentFeature) {
    let x = currentFeature;
    let distance = `${roundedDistance(currentFeature.properties.distance)}`;
    let distanceText = `${roundedDistance(currentFeature.properties.distance)} Miles`;
    if (distance == 'NaN') {

      const options = { units: 'miles' };
        x = places[0];
        let distance = turf.distance(
            turf.point([lng, lat]),
            x.geometry,
            options
        );
     distanceText = `${roundedDistance(distance)} Miles`;
    }

    const popup = new mapboxgl.Popup({ closeOnClick: false })
      .setLngLat(currentFeature.geometry.coordinates)
      .setHTML(`<h3 class="${currentFeature.properties.NetworkorFRC.startsWith('FRC') ? 'bg-[#f84d4d]': 'bg-[#4264fb]'} text-white">${currentFeature.properties.Organization} <MagnifyingGlassIcon className="h-6 w-65" aria-hidden="true" /> </h3><h4>${currentFeature.properties.Address} ${currentFeature.properties.City}, CA</h4><h5 class="font-bold p-[5px]">${distanceText}</h5>`)

      .addTo(map.current);
  }


  function query(evnt) {
    const text = evnt.target.value;
    if (text.length >= 3) {
      const r = fuse.current.search('='+text, {limit: 15});
      setResults(r)
    }
  }

  useEffect(() => {
    fuse.current = new Fuse(places, {
      keys: ['properties.Organization', 'properties.Address']
    })

    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      attributionControl: false,
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });
    geocoder.current = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken, // Set the access token
          mapboxgl: map.current, // Set the mapbox-gl instance
          minLength: 3,
          zoom: 10.1,
          placeholder: 'Type an address to find nearby family resource centers',
          marker: true, // Use the geocoder's default marker style
          bbox: [-124.409591, 32.534156, -114.131211, 42.009518] // Set the bounding box coordinates (xmin, ymin, xmax, ymax)
        });
    map.current.on('zoomend', () => {
    });
    geocoder.current.on('result', (event) => {
      setResults(1)
      /*
      let popUps = document.querySelectorAll('.mapboxgl-popup');
      if (popUps.length > 0) {
        for (let item in popUps) {
          console.log(item)
          item.classList.add('hidden');
        }
      }
      */
      const searchResult = event.result.geometry;
      // Code for the next step will go here
      const options = { units: 'miles' };
      for (const frc of places) {
        frc.properties.distance = turf.distance(
          searchResult,
          frc.geometry,
          options
        );
      }
      places.sort((a, b) => {
        if (a.properties.distance > b.properties.distance) {
          return 1;
        }
        if (a.properties.distance < b.properties.distance) {
          return -1;
        }
        return 0; // a must be equal to b
      });
      setResults(places);
      places.slice(0, 3).map((e, i) => {console.log(e);createPopUp(e)});
      forceUpdate();
      setIsOpen(true);
      goTo(places[0].geometry.coordinates, places[0].properties.Address)
    });
    map.current.on('click', (event) => {
        const net = map.current.queryRenderedFeatures(event.point, {
            layers: ['networks', 'lone-frcs']
        });
        let clickedPoint = net[0];

        if (results.length == 0) {
            getMyLocation();
            if (clickedPoint) {
                createPopUp(clickedPoint);
                goTo(clickedPoint.geometry.coordinates, clickedPoint.properties.Address);
            }
            setIsOpen(true);
        }
    })
    map.current.on('load', () => {
      map.current.loadImage(markerImageRed,
        (error, image) => {
          if (error) throw error;
          map.current.addImage('markerImageRed', image);
      })
      map.current.loadImage(markerImageBlue,
        (error, image) => {
          if (error) throw error;
          map.current.addImage('markerImageBlue', image);
      })
      map.current.addLayer({
          id: 'networks',
          type: 'symbol',
          layout: {
            'icon-allow-overlap': true,
            'icon-image': 'markerImageBlue'
          },
          source: {
            type: 'geojson',
            cluster: false,
            data: {
              type: 'FeatureCollection',
              features: places.filter(place => place.properties.NetworkorFRC !== 'FRC')
            }
          }
      });
      map.current.addLayer({
          id: 'lone-frcs',
          type: 'symbol',
          layout: {
            'icon-allow-overlap': true,
            'icon-image': 'markerImageRed'
          },
          source: {
            type: 'geojson',
            cluster: false,
            data: {
              type: 'FeatureCollection',
              features: places.filter(place => place.properties.NetworkorFRC === 'FRC')
            }
          }
      });
      map.current.addControl(new mapboxgl.AttributionControl({
        customAttribution: 'Implemented by Civic Hacker, LLC'
      }));
      map.current.addControl(new mapboxgl.NavigationControl());
      document.getElementById('geocoder').appendChild(geocoder.current.onAdd(map.current));
      map.current.resize();
      // create legend
      const legend = document.getElementById('legend');

      layers.forEach((layer, i) => {
        const color = colors[i];
        const item = document.createElement('div');
        const key = document.createElement('span');
        key.className = 'legend-key rounded-sm  w-[10px] inline-block mr-[5px] h-[10px]';
        key.style.backgroundColor = color;

        const value = document.createElement('span');
        value.innerHTML = `${layer}`;
        item.appendChild(key);
        item.appendChild(value);
        legend.appendChild(item);
      });

    })

  });

  return (
  <>      <div id="geocoder" className="absolute z-10 flex flex-1 object-center w-full m-0 md:w-1/2 md:left-1/3 top-2"></div>
    <div className="w-screen h-full flex flex-row flex-grow">
    <div className="hidden md:block md:w-2/5 overflow-y-auto">
      <div className="divide-y">
        {results.length === 0 && <>
          {places.map((place) => {
            return <ListItem key={place.properties.Address} coordinates={place.geometry.coordinates} properties={place.properties} selected={selected} onClick={() => goTo(place.geometry.coordinates)} mapRef={map}/>
          })}
        </>}
        {results.length > 0 && <>
        {results.map((place) => {
            return <ListItem key={place.properties.Address} coordinates={place.geometry.coordinates} properties={place.properties} selected={selected} />
          })}
          </>
        }
      </div>
    </div>

    <div ref={mapContainer} className="w-full" />
    <div className="map-overlay absolute bottom-0 right-0 mr-[20px] bg-white p-3 rounded w-[140px] mb-[40px]" id="legend"></div>
    <div onClick={() => getMyLocation()} className="map-overlay absolute bottom-0 left-0 ml-[20px] bg-white p-3 rounded-full shadow-md mb-[40px]">
      <GlobeAmericasIcon className="w-6 h-6" />
      </div>

    <Dialog className="block md:hidden" open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-start mt-12 justify-center p-4">
        <Dialog.Panel className="mx-auto p-2 mr-[20px] w-full md:w-4/5 max-w-2xl rounded bg-white">
          <div className="overflow-y-auto max-h-[45rem]">
           <div className="flex justify-end">
                <XMarkIcon onClick={() => setIsOpen(false)} className="w-10 h-10"/>
           </div>
            <div className="divide-y">
              {results.length === 0 && <>
                {places.map((place) => {
                  return <ListItem key={place.properties.Address} coordinates={place.geometry.coordinates} properties={place.properties} selected={selected} mapRef={map} />
                })}
              </>}
              {results.length > 0 && <>
              {results.map((place) => {
                return <ListItem key={place.properties.Address} coordinates={place.geometry.coordinates} properties={place.properties} selected={selected} />
              })}
                </>
              }
            </div>
        </div>

        </Dialog.Panel>
      </div>
    </Dialog>
    </div>
  </>
  );
}
